

export const Data = [
    {
        name: 'Отчет библиотека 2019-2020 СОШГ 1.',
        link : "https://drive.google.com/file/d/1pleDTKJYGt1yV-_vaiDxF8LQqb3_X752/view?usp=sharing"
    },
    {
        name: 'Анализ.Отчет библиотеки.',
        link : "https://docs.google.com/document/d/1JmKAS-HRNqXJ2gtNbF6EsUQ94RKUiEqY/edit?usp=sharing&ouid=114033912863507555037&rtpof=true&sd=true"
    },
    {
        name: 'Анализ проведенного опроса (1).',
        link : "https://drive.google.com/file/d/10cqGTn9C8W-_25SdNgiD2OqKSpsLPczx/view?usp=sharing"
    },
    {
        name: 'План работы.',
        link : "https://docs.google.com/document/d/1MnUVMMxxQ8p9bR_dpRMeFUbxUSi44HRT/edit?usp=sharing&ouid=114033912863507555037&rtpof=true&sd=true"
    },
    {
        name: 'Аналитическая справка по результатам адм кон нач школе СОШГ 1',
        link : "https://drive.google.com/file/d/1geiznhnpbpIG1OnDAfuacfAAO6PIV891/view?usp=sharing"
    },
    {
        name: 'План 2021-2022.',
        link : "https://docs.google.com/document/d/1MnUVMMxxQ8p9bR_dpRMeFUbxUSi44HRT/edit?usp=sharing&ouid=114033912863507555037&rtpof=true&sd=true"
    },
    {
        name: 'Pезультаты ОРТ сведения 2020.',
        link : "https://docs.google.com/document/d/11liihoR8_u2FDZmwp6c4tArKEvDmm1wy/edit?usp=sharing&ouid=114033912863507555037&rtpof=true&sd=true"
    },
    {
        name: 'Анализ результатов анкетирования «Учитель глазами учеников»',
        link : "https://drive.google.com/file/d/1rTHJSaNly3Ts1d8LDIKSpAA7Y86waIQe/view?usp=sharing"
    },
    {
        name :"Opt 2019-2020",
        link : "https://drive.google.com/file/d/1tGdR7rJCkXVHdvh5PWV2FQ8GTBiIMRla/view?usp=sharing"
    },
    {
        name :"Opt 2020-2021",
        link : "https://drive.google.com/file/d/1JzOr1Ea5X7nsbwFfT6wCd1gw7n70vMW0/view?usp=sharing"
    }
]