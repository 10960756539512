import Header from "./Header-Footer/Header";
import Footer from "./Header-Footer/footer";


export const HomeLayout = ({children}) => {
    return (
        <div>
            <Header/>
            <main>
                {children}
            </main>
            <Footer/>
        </div>
    )
}
