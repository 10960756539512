import React from 'react';
import {NavLink} from "react-bootstrap";
import {Data} from "../../images/doc/data";

const DocPdf = () => {
    return (
        <div className="container">
            <div className="doc-block">
                <div className="doc-pdf">
                    <h1>
                        Внутренние нормативные документы
                    </h1>
                </div>
                <div className="row">
                    {
                        Data.map((item)=>{
                            return (
                               <div className={"col-lg-4 col-md-6  gap-4 "}>
                                   <NavLink href={item.link} className=" doc-pdf-box">
                                       <div className="doc-pdf-box-img">
                                           <img src={item.img} alt=""/>
                                       </div>
                                       <div className="doc-pdf-box-title">
                                           <h1>{item.name}</h1>
                                           <p className="p">Посмотреть</p>
                                       </div>
                                   </NavLink>
                               </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default DocPdf;