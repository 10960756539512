
import {useIntl} from "react-intl";
import {NavLink} from "react-router-dom";
import {HomeLayout} from "./components/HomeLayout";

export default function Contact() {
    const {formatMessage} = useIntl()
    return (
        <HomeLayout>
            <div className="contact">
                <iframe className="contact_map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d608.5808514698358!2d73.0044663167896!3d40.929672259711325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38bd15d27f2bc77b%3A0x587cce8371f2aef4!2z0KjQutC-0LvQsCDihJYxINCT0LjQvNC90LDQt9C40Y8!5e0!3m2!1sru!2skg!4v1649523393425!5m2!1sru!2skg"
                     allowFullScreen="" loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"/>
                <div className="contact_block">
                    <h1>Кoнтакты</h1>
                    <div className="contact_block_box">
                        <p>Телефон:</p>
                        <a href="tel:+(037-22)-2-50-25"><h5> (037-22)-2-50-25, 2-03-11</h5></a>
                        {/*<a href="tel:+996 999 010 040 ">  <h5>+996 999 010 040 </h5></a>*/}
                    </div>
                    <div className="contact_block_box">
                        <p>E-mail:</p>
                        <h6> <a href="mailto:babkina.edu@gmail.com">babkina.edu@gmail.com</a></h6>
                    </div>
                    <div className="contact_block_box">
                        <p>Адрес:</p>
                        <h5>
                             (Кыргызская Республика , г. Жалал-Абад ,ул Ж.Бакиев №17)
                        </h5>
                    </div>
                    <NavLink to="https://go.2gis.com/xa4x2q"><button className="contact_block_btn"><a href="#">Перейти 2GIS</a></button></NavLink>
                </div>
                <div className="btn_con">
                    <NavLink to="https://go.2gis.com/xa4x2q"><button className="contact_block_btn2"><a href="#">Перейти 2GIS</a></button></NavLink>
                </div>
            </div>
        </HomeLayout>
    )
}
