// import {getLocale, setLocale} from "../../redux/reducers/main";
// import {languages} from "../../constants/languages";
// import {LOCALES} from "../../intl/locales";

import {useIntl} from "react-intl";
import { push as Menu } from 'react-burger-menu'
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import downArrow from "../../images/down-arrow.png"
import logo from "../../images/Ellipse 1.png"
import React from "react";

export default function Header() {
    const dispatch = useDispatch();
    const {formatMessage, locale} = useIntl();
        const showSettings = (event) => {
            event.preventDefault();
        }
    return (
        <div id='header'>
            <div className="container">
                <div className="headers">
                    <NavLink to={"/"} className=" header_logo">
                        <img src={logo} alt=""/>
                    </NavLink>
                        <div className="navbar">
                            <NavLink to={"/history"} className="navbar__a">История школы</NavLink>
                            <NavLink to={"/teach"} className="navbar__a">ПЕДАГОГИ</NavLink>
                            <NavLink to={"/teach"} className="navbar__a">Документы<span><img className="header_img"
                                                                                        src={downArrow} alt=""/></span>
                                <div className="header_block">
                                    <NavLink to={"/doc/normative"} className="navbar__a">Нормативные документы
                                        КР</NavLink>
                                    <NavLink to={"/doc/pdf"} className="navbar__a">Внутренние нормативные
                                        документы</NavLink>
                                    <NavLink to={"/doc/moni"} className="navbar__a">Mониторинг</NavLink>
                                    <NavLink to={"/mtb"} className="navbar__a">МТБ</NavLink>
                                    <NavLink to={"/edu-met"} className="navbar__a">Учебно-методический</NavLink>
                                </div>
                            </NavLink>
                            <NavLink to={"/regu"} className="navbar__a">Положения</NavLink>
                            <NavLink to={"/news"} className="navbar__a">НОВОСТИ</NavLink>
                            <NavLink to={"/contact"} className="navbar__a">Контакты</NavLink>
                        </div>
                </div>
                <div className="burger1">
                    <Menu right >
                        <div className="">
                            <div className="news-title">
                                <h1>Меню</h1>
                            </div>
                            <div className="burger">
                                <NavLink to={"/history"} className="burger__a">История школы</NavLink>
                                <NavLink to={"/teach"} className="burger__a">ПЕДАГОГИ</NavLink>
                                <ul>ДОКУМЕНТЫ
                                    <li>

                                        <NavLink to={"/doc/normative"} className="burger__a">Нормативные документы
                                            КР</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to={"/doc/pdf"} className="burger__a">Внутренние нормативные
                                            документы</NavLink>
                                    </li>
                                    <li>                                        <NavLink to={"/doc/moni"} className="burger__a">Mониторинг</NavLink>
                                    </li>
                                    <li>
                                                                             <NavLink to={"/mtb"} className="burger__a">МТБ</NavLink>

                                    </li>
                                    <li>                                    <NavLink to={"/edu-met"} className="navbar__a">Учебно-методический</NavLink>
                                    </li>
                                </ul>
                                <NavLink to={"/regu"} className="burger__a">Положения</NavLink>
                                <NavLink to={"/news"} className="burger__a">НОВОСТИ</NavLink>
                                <NavLink to={"/contact"} className="burger__a">Контакты</NavLink>
                            </div>
                        </div>
                    </Menu>
                </div>
            </div>
        </div>
    )
}
// <div className="select">
//                         <div className="select_block">
//                             <select name="main" value={locale} onChange={(e) => dispatch(setLocale(e.target.value))}>
//                                 {languages.map((elem, i) => (
//                                     <option key={LOCALES[elem.key]} value={LOCALES[elem.key]}>{elem.flag}{elem.text}</option>
//                                 ))}
//                             </select>
//                         </div>
//                     </div>