import {useIntl} from "react-intl";
import React from "react";
import {NavLink} from "react-router-dom";


export default  function  IndexLandingHero(){
    const {formatMessage} = useIntl();
    return (
        <section id="indexHero">
            <div className="container">
                <div className="row">
                    <div className="col-md-6"/>
                    <div className="col-md-6">
                        <div className="hero">
                            <h1><p><i>Вашу будущее .( Жалал-Абад )</i></p>
                                Средняя общеобразовательная школа-гимназия №1 имени Михаилa Николаевичa Бабкинa
                            </h1>
                        </div>
                        <NavLink to={"/history"}>
                            <div className="index-about-block">
                                <button>Узнать больше</button>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        </section>
    )
}