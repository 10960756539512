import {IntlProvider} from "react-intl";
import {useSelector} from "react-redux";

import {MESSAGES} from "./intl/messages";
import IndexLanding from "./IndexLanding";

import {Route, Routes} from "react-router-dom";

import  "./styles/index.scss"
import DocHome from "./Documents/doc-home";
import DocHomePdf from "./Documents/doc-home-pdf";
import Contact from "./contact";
import History from "./history";
import NewsIndex from "./news-index";
import Moni from "./moni";
import Mtb from "./mtb";
import Regu from "./regu";
import Teach from "./teach";
import EduMetLanding from "./edu-met-landing";


export default function  App(){
    const {main} = useSelector(allState => allState);
    return(
        <IntlProvider locale={main.locale} messages={MESSAGES[main.locale]}>
            <Routes>
                <Route exact path="/" element={<IndexLanding />} />
                <Route exact path="/doc/normative" element={<DocHome />} />
                <Route exact path="/doc/pdf" element={<DocHomePdf />} />
                <Route exact path="/contact" element={<Contact />} />
                <Route exact path="/history" element={<History />} />
                <Route exact path="/news" element={<NewsIndex />} />
                <Route exact path="/doc/moni" element={<Moni />} />
                <Route exact path="/mtb" element={<Mtb />} />
                <Route exact path="/regu" element={<Regu />} />
                <Route exact path="/teach" element={<Teach />}/>
                <Route exact path="/edu-met" element={<EduMetLanding />}/>
            </Routes>
        </IntlProvider>
    )
}