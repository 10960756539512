import React from 'react';
import {HomeLayout} from "./components/HomeLayout";
import ReguIndex from "./components/regu/regu-index";

const Regu = () => {
    return (
        <HomeLayout>
            <ReguIndex num={"-1"}/>
        </HomeLayout>
    );
};

export default Regu;