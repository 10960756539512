import React from 'react';
import {HomeLayout} from "../components/HomeLayout";
import DocPdf from "../components/Documents/doc-pdf";

const DocHomePdf = () => {
    return (
        <HomeLayout>
            <DocPdf />
        </HomeLayout>
    );
};

export default DocHomePdf;