import {LOCALES} from "../locales";


export const Header = {
    [LOCALES.RUSSIAN]:{
        'header':'home'
    },
    [LOCALES.KYRGYZ]:{
        'header':'hooome'
    },
}