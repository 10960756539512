import React from 'react';
import {HomeLayout} from "./components/HomeLayout";
import {teachersData} from "./teachersData";
const Teach = () => {
    return (
        <HomeLayout >
            <div className="news-title">
                <h1>ПЕДАГОГИ</h1>
            </div>
            <div className={"container"}>
                <div className="row">
                    {
                        teachersData.map((item)=>{
                            return(
                                <div className="col-md-4 col-sm-6  col-12">
                                    <div className="teach">
                                        <div className="teach_block">
                                            <img src={item.img} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </HomeLayout>
    );
};

export default Teach;