import React from 'react';
import dr from "../../images/Снимок экрана 2022-04-12 в 02.21.13.png"
import {NavLink} from "react-router-dom";

const IndexLandingDr = () => {
    return (
        <div id="dr">
          <div className="container ">
              <div className="row">
                  <div className="col-md-6 col-12">
                      <div className="index-dr-box">
                          <img src={dr} alt="" className="index-dr-box-img"/>
                      </div>
                  </div>
                  <div className="col-md-6 col-12">
                      <h1 className="index-dr-desc">
                          “Не бывает плохих детей, которые желают быть плохими, - плохими бывают только несчастные дети. Счастливые дети растут и вырастают хорошими.”                    </h1>
                      <div className="index-dr-block">
                          <p>
                              Директор:
                                 <strong> <i>   Касымбекова Д.Т</i></strong>
                          </p>
                      </div>
                      <NavLink to={"/teach"}>
                          <div className="container">
                              <div className="index-about-block">
                                  <button>Узнать больше...</button>
                              </div>
                          </div>
                      </NavLink>
                  </div>
              </div>
          </div>
        </div>
    );
};

export default IndexLandingDr;