import React from 'react';
import {Data} from "../news/data";
import {NavLink} from "react-router-dom";
import imgPerson from "../../images/Снимок экрана 2022-04-13 в 01.20.34.png"

const IndexLandingAbout = () => {
    return (
        <section id="about">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="index-about-title mb-5">
                            <h1>
                                Наша миссия
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <h1 className="index-about-desc">
                            Миссия СОШГ №1 им.М.Бабкина заключается в обеспечении высокого качества образования и в
                            воспитании личности с высоким уровнем культуры, здорового гражданина с активной жизненной
                            позицией, способного принимать эффективные решения и нести ответственность за судьбу своей
                            страны, патриота своего Отечества. </h1>
                    </div>
                    <div className="col-lg-6">
                        <div className="index-about-box">
                            <img src={imgPerson} alt=""
                                 className="index-about-box-img"/>
                            <img src={"https://aktilek.kg/static/media/dots1.b067c4bf.png"} alt=""
                                 className="index-about-box-imgBk"/>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="index-about-title">
                            <h1>
                                Цель
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <h1 className="index-about-desc">
                            Формирование личностно-ориентированного образовательного пространства путем внедрения новых
                            инновационных подходов, ориентированных на развитие субъектов образовательного процесса в
                            условиях формирования личностно-профессиональных компетенций педагогов и личностно-учебных
                            компетенций учащихся.
                        </h1>
                    </div>
                    <div className="col-lg-12">
                        <div className="index-about-title">
                            <h1>
                                Задачи
                            </h1>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <h1 className="index-about-desc">
                            <br/> • создание условий для повышения качества образовательного процесса, обеспечение его стабильности и  результативности;
                            <br/> • внедрение современных педагогических технологий и методик обучения и воспитания;
                            <br/> • создание эффективной системы патриотического, гражданского и духовно-нравственного воспитания;
                            <br/> • совершенствование системы контроля качества образования, включая проведение мониторинга;
                            <br/> • создание условий для эффективного использования кадрового потенциала гимназии;
                            <br/>• формирование положительного имиджа гимназии;
                            <br/>• совершенствование системы воспитания здорового образа жизни, системы здоровье сберегающих технологий обучения.
                        </h1>
                    </div>
                    <div className={"news-title"}>
                        <h1>Последние новости</h1>
                    </div>
                    <div className="row">
                        {
                            Data.slice(0 ,3).map((item)=>{
                                return (
                                    <div className={"col-lg-4 col-md-6  gap-4 "}>
                                        <div className="doc-pdf-box">
                                            <div className="doc-pdf-box-img">
                                                <img src={item.img} alt=""/>
                                            </div>
                                            <div className="doc-pdf-box-title">
                                                <h1>{item.title}</h1>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className={"news-title mb-5"}>
                        <NavLink to={"/news"}>Читать ещe</NavLink>
                    </div>


                </div>
            </div>
        </section>
    );
};

export default IndexLandingAbout;