import React from 'react';
import {HomeLayout} from "../components/HomeLayout";
import NorDoc from "../components/Documents/nor-doc";

const DocHome = () => {
    return (
        <HomeLayout>
            <NorDoc/>
        </HomeLayout>
    );
};

export default DocHome;