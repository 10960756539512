import React from 'react';
import logo from "../../images/Ellipse 1.png"
import {NavLink} from "react-bootstrap";
import downArrow from "../../images/down-arrow.png";
import loc from "../../images/flaticons/location.png"
import tel from "../../images/flaticons/telephone-handle-silhouette.png"
import email from "../../images/flaticons/email.png"
import ins from "../../images/flaticons/instagram.png"
import face from "../../images/flaticons/facebook (1).png"
import what from "../../images/flaticons/whatsapp.png"

const Footer = () => {
    return (
        <div id="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-3 col-12">
                        <div className="footer-img">
                            <img src={logo} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-4 col-12">
                        <div className="">
                            <div className="footer">
                                <NavLink to={"/history"} className="footer__a">История школы</NavLink>
                                <NavLink to={"/teach"} className="footer__a">ПЕДАГОГИ</NavLink>
                                <NavLink to={"#"} className="footer__a">Документы<span><img className="footer_img" src={downArrow} alt=""/></span>
                                    <div className="footer_block">
                                        <NavLink to={"/doc/normative"} className="footer__a">Нормативные документы
                                            КР</NavLink>
                                        <NavLink to={"/doc/pdf"} className="footer__a">Внутренние нормативные
                                            документы</NavLink>
                                        <NavLink to={"/doc/moni"} className="footer__a">Mониторинг</NavLink>
                                        <NavLink to={"/mtb"} className="footer__a">МТБ</NavLink>
                                        <NavLink to={"/edu-met"} className="navbar__a">Учебно-методический</NavLink>
                                    </div>
                                </NavLink>
                                <NavLink to={"/regu"} className="footer__a">Положения</NavLink>
                                <NavLink to={"/news"} className="footer__a">НОВОСТИ</NavLink>
                                <NavLink to={"/contact"} className="footer__a">Контакты</NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 col-12">
                        <h1 className="footer_title">Наши контакты</h1>
                        <div className="footer_icons">
                            <div className="footer_icons_div">
                                <img src={loc} alt=""/>
                            </div>
                            <h5>
                                (Кыргызская Республика , г. Жалал-Абад ,ул Ж.Бакиев №17)
                            </h5>
                        </div>
                        <div className="footer_icons">
                            <div className="footer_icons_div">
                                <img src={tel} alt=""/>
                            </div>
                            <div className="footer_icons_div2">
                                <a href="tel:+(037-22)-2-50-25"><h5> (037-22)-2-50-25</h5></a>
                                <a href="tel:+(037-22)-2-03-11"><h5> (037-22)-2-03-11</h5></a>
                            </div>
                        </div>
                        <div className="footer_icons">
                            <div className="footer_icons_div">
                                <img src={email} alt=""/>
                            </div>
                            <h5><a href="mailto:babkina.edu@gmail.com">babkina.edu@gmail.com</a></h5>
                        </div>
                        <div className="footer_gmails">
                            <NavLink href={"https://www.instagram.com/school_n_1"}>
                                <div>
                                    <div className="footer_icons_div">
                                        <img src={ins} alt=""/>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink href={"https://www.facebook.com/people/%D0%98%D0%BC-%D0%9C%D0%B8%D1%85%D0%B0%D0%B8%D0%BB%D0%B0-%D0%91%D0%B0%D0%B1%D0%BA%D0%B8%D0%BD%D0%B0/100048489881905/"}>
                                <div>
                                    <div className="footer_icons_div">
                                        <img src={face} alt=""/>
                                    </div>
                                </div>
                            </NavLink>
                            <NavLink href={"https://www.whatsapp.com/?lang=ru"}>
                                <div>
                                    <div className="footer_icons_div">
                                        <img src={what} alt=""/>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;