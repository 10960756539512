import React from 'react';
import {HomeLayout} from "./components/HomeLayout";
import HistoryIndex from "./components/History/history-index";

const History = () => {
    return (
        <HomeLayout>
           <HistoryIndex/>
        </HomeLayout>
    );
};

export default History;