import {configureStore} from "@reduxjs/toolkit";
import main from './reducers/main'

const store = configureStore({
    reducer:{
        main
    },
    middleware:[]
})

export default store