import {createSlice} from "@reduxjs/toolkit";
import {LOCALES} from "../../intl/locales";


const initialState = {
    locale: LOCALES.RUSSIAN,
    card : []
}

const main = createSlice({
    name: 'main',
    initialState,
    reducers: {
        setLocale(state, action) {
            state.locale = action.payload;
        },
        getLocale(state,action){
            state.card = {...action.payload}
        }

    }
});

export const {
    setLocale,getLocale
} = main.actions;
export default main.reducer;