import React from 'react';
import {NavLink} from "react-bootstrap";
import file from "../../images/folder.png";
import {Data} from "./data";

const ReguIndex = ({num }) => {



    return (
        <div className="container">
            <div className="container">
                <div className="news-title ">
                    <h1>
                         документы
                    </h1>
                </div>
                <div className="row mb-5">
                    {
                        Data.slice(0 ,num).map(item=>{
                            return (
                                <NavLink href={item.link} className="col-lg-3 gap-4 col-md-4 col-sm-6">
                                    <a>
                                        <div className="mtb_block">
                                            <img src={file} alt=""/>
                                            <h1>{item.name}</h1>
                                        </div>
                                    </a>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
};

export default ReguIndex;