import React from 'react';
import {Data} from "../../Documents/doc";
import {NavLink} from "react-bootstrap";

const NorDoc = () => {



    return (
        <div>
            <div className="container">
                <div className="nor-doc">
                    <div className="nor-doc-title">
                        <h1>Нормативные документы КР</h1>
                    </div>
                    <div className="nor-doc-block">
                        <ul>
                            {
                                Data.map((item ,inx)=>{
                                    return(
                                        <li>
                                            <NavLink className="nor-doc-block-link" href={item.link}>{inx+1}. {item.name}</NavLink>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default NorDoc;