

export const Data = [
    {
        "name":"Коллективный договор",
        "link":"https://drive.google.com/file/d/19AKxS2jU5IOu-ZKGUHIB9WH4YrgolCFH/view?usp=sharing"
    },
    {
        "name":"Положение о методическом совете",
        "link":"https://drive.google.com/file/d/1DrMhHEogtfnISh6KIbEX2oPyKGsncWzh/view?usp=sharing"
    },
    {
        "name":"Положение о портфолио",
        "link":"https://drive.google.com/file/d/1_v_vmGqJ9t0LhqkcKP4UMlKG_h8kXNUV/view?usp=sharing"
    },
    {
        "name":"Положение о поурочном плане",
        "link":"https://drive.google.com/file/d/1nbo6521iTJM7hCijjqlAb2s5tFgtppb4/view?usp=sharing"
    },
    {
        "name":"Положение о почте доверия",
        "link":"https://drive.google.com/file/d/1QYEB6hc79XmSXXUZuwUFJs0ZxJ6v0zsk/view?usp=sharing"
    },
    {
        "name":"Положение о приёма СОШГ 1.",
        "link":"https://drive.google.com/file/d/1lTlyswvBeNltfJ-_Efo7mSHAJBUZkqct/view?usp=sharing"
    },
    {
        "name":"Положение о учебном кабинете`",
        "link":"https://drive.google.com/file/d/14Vott-6WgM8qPtoJV__VrPt5dZ6Kh0o6/view?usp=sharing"
    },
    {
        "name":"Положение о школьной форме",
        "link":"https://drive.google.com/file/d/1sKVV07KfVIGOxSovVnSTmt6TEIX70v8w/view?usp=sharing"
    },
    {
        "name":"Положение Педагогическом совете",
        "link":"https://drive.google.com/file/d/1jzweC4WdyPrybSdedNX3_xoaush4YiT3/view?usp=sharing"
    },
    {
        "name":"Положение посещение семьи",
        "link":"https://drive.google.com/file/d/1ELRhsoJgNFRSvRnKu60q3sHbv3hHu9Qn/view?usp=sharing"
    },
    {
        "name":"Положение апелляционной комиссии",
        "link":"https://drive.google.com/file/d/1moSp2Tn-60grcCqCNnW0lRv41JLDD5aH/view?usp=sharing"
    },
    {
        "name":"Положение и гимназических классах",
        "link":"https://drive.google.com/file/d/1n0xEB6BhYbk2i2U3MOrmstD3X8wnhm-r/view?usp=sharing"
    },
    {
        "name":"Положение о внутри школьном контроле",
        "link":"https://drive.google.com/file/d/1wiresLGFjpZdieK2gswj6I_R16BKybp-/view?usp=sharing"
    },
    {
        "name":"Положение о классном родительском комитете",
        "link":"https://drive.google.com/file/d/1l8X9xF-hs9FcTHR41L_8mL7JaDR6yvtq/view?usp=sharing"
    },
    {
        "name":"Положение о комиссии по профессиональный этике",
        "link":"https://drive.google.com/file/d/1sucX-j5J_yxSvfTOnF13rIPpo4pNzoHy/view?usp=sharing"
    },
    {
        "name":"Положение о методическом объединении",
        "link":"https://drive.google.com/file/d/1Yc8kSCDwFQJa00pmsGEUDCyWuCQVtYPQ/view?usp=sharing"
    },
    {
        "name":"Положение о модульно рейтинговой системе",
        "link":"https://drive.google.com/file/d/1toZXLdQHID9Z9WvtlSonOOQSgkXQiUyE/view?usp=sharing"
    },
    {
        "name":"Положение о попечительском совете",
        "link":"https://drive.google.com/file/d/1LzwjLt2ZUZI96lXUMqLlH6jcZEROZCGN/view?usp=sharing"
    },
    {
        "name":"Положение о порядке ведения ученических тетрадей",
        "link":"https://drive.google.com/file/d/1y6vh80uncXlwQqjdblwztzfh-1TFAnXF/view?usp=sharing"
    },
    {
        "name":"Положение о порядке организации, посещении",
        "link":"https://drive.google.com/file/d/1H-DW1UhBwh1GSPnK1WYcD_RmNzVUhYfP/view?usp=sharing"
    },
    {
        "name":"Положение о порядке учёта и контроля",
        "link":"https://drive.google.com/file/d/1qRnw-fpI9cCEYYPuqZLmMOucwy8kS7pX/view?usp=sharing"
    },
    {
        "name":"Положение о посещении учебных занятий",
        "link":"https://drive.google.com/file/d/1jHjXOqdAaF-FrV73j00QyLXiz_Yk4DKk/view?usp=sharing"
    },
    {
        "name":"Положение о проведении внутри школьного мониторинга и экзаменационной",
        "link":"https://drive.google.com/file/d/11kuVSASW107CmDAa_xL_mxQV5unOYeBu/view?usp=sharing"
    },
    {
        "name":"Положение о проведении итоговой государственной аттестации",
        "link":"https://drive.google.com/file/d/1ju0tfyTIMWCMKOXoO2eCdeczVvYG52xs/view?usp=sharing"
    },
    {
        "name":"Положение о проведении открытого урока",
        "link":"https://drive.google.com/file/d/1tqUVWHNjZnJQ8MVJtg6Ucz89UOV9-NB8/view?usp=sharing"
    },
    {
        "name":"Положение о системе оценивания знаний_умений.",
        "link":"https://drive.google.com/file/d/1Co6ac2rLZgGf6NGflyh_sSd7VGWs3jDN/view?usp=sharing"
    },
    {
        "name":"Положение о системе оценки качества",
        "link":"https://drive.google.com/file/d/1133ENiMgtMxbjfavskztDBAJtcNwHEUY/view?usp=sharing"
    },
    {
        "name":"Положение о социально психологической службе.",
        "link":"https://drive.google.com/file/d/165PC1rOgu0MEykLdbjknrOae0-53dwy0/view?usp=sharing"
    },
    {
        "name":"Положение о форме и порядке промежуточной аттестации",
        "link":"https://drive.google.com/file/d/1PDTWVWH8dw37u4V9GhGbZAmmoqW0PYfd/view?usp=sharing"
    },
    {
        "link":"https://drive.google.com/file/d/13DYQfmd_JsYD0o4U7rapoq0D_gE-7QoI/view?usp=sharing",
        "name":"Положение о школьной предметной олимпиаде"
    },
    {
        "link":"https://drive.google.com/file/d/1sgZNjc8LiN1YDbOlAsSsrgD8nNfvGisO/view?usp=sharing",
        "name":"Кодекс профессиональной этики"
    }
]