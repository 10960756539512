import React from 'react';
import {Data} from "./data";
const NewsLanding = () => {

    return (
        <div className="container">
            <div className={"news-title"}>
                <h1>Последние новости</h1>
            </div>
            <div className="row">
                {
                    Data.map((item)=>{
                        return (
                            <div className={"col-lg-4 col-md-6  gap-4 "}>
                                <div className=" doc-pdf-box">
                                    <div className="doc-pdf-box-img">
                                        <img src={item.img} alt=""/>
                                    </div>
                                    <div className="doc-pdf-box-title">
                                        <h1>{item.title}</h1>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    );
};

export default NewsLanding;