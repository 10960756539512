
import Ustav from "../doc/Устав.pdf"
import sert220 from "../doc/Сертификат 220.pdf"
import sertRUS from "../doc/сертификат русский.pdf"
import sertKYR from "../doc/сертификат кыргызча.pdf"
import sertAKK from "../doc/сертификат об аккредитации.pdf"
import sert from "../doc/сертификат.pdf"
import svi from "../doc/свидетельство.pdf"
import li from "../doc/лицензия.pdf"
import cont from "../doc/Контрольные_нормативы_условий_осуществления_образ.pdf"
import img1 from "../doc/Снимок экрана 2022-04-09 в 01.15.33.png"
import img2 from "../doc/Снимок экрана 2022-04-09 в 01.16.35.png"
import img3 from "../doc/Снимок экрана 2022-04-09 в 01.16.53.png"
import img4 from "../doc/Снимок экрана 2022-04-09 в 01.17.03.png"
import img5 from "../doc/Снимок экрана 2022-04-09 в 01.17.17.png"
import img6 from "../doc/Снимок экрана 2022-04-09 в 01.17.39.png"
import img7 from "../doc/Снимок экрана 2022-04-09 в 01.18.04.png"


export const Data = [
    {
        "name": "Cертификат",
        "link" : sert,
        "img" : img1
    },
    {
        "name": "Cвидетельство №594",
        "link" : svi,
        "img" : img2
    },
    {
        "name": "Cертификат №000220",
        "link" : sert220,
        "img" : img3
    },
    {
        "name": "сертификат кыргызча",
        "link" : sertKYR,
        "img" : img4
    },
    {
        "name": "лицензия",
        "link" : li,
        "img" : img5
    },
    {
        "name": "сертификат русский",
        "link" : sertRUS,
        "img" : img6
    },
    {
        "name": "Контрольные нормативы условий осуществления образ",
        "link" : cont,
        "img" : img7
    },
    {
        "name": "сертификат об аккредитации",
        "link" : sertAKK,
        "img" : img4
    },
    {
        "name": "Устав",
        "link": Ustav,
        "img" : img6
    },
]