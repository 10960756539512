import IndexLandingHero from "./components/IndexLanding/IndexLandingHero";
import {HomeLayout} from "./components/HomeLayout";
import IndexLandingAbout from "./components/IndexLanding/index-landing-about";
import IndexLandingDr from "./components/IndexLanding/index-landing-dr";
import React from "react";


export default function  IndexLanding(){
    return (
        <HomeLayout>
            <IndexLandingHero/>
            <IndexLandingAbout/>
            <IndexLandingDr/>
        </HomeLayout>
    )
}