import React from 'react';
import Miha from "../../images/Михаил_Николаевич_Бабкин.jpg"
const HistoryIndex = () => {
    return (
        <div className="container">
            <div className="history-titles">
                <div className="history-titles-box row">
                    <img src={Miha} alt="" className="col-md-4"/>
                    <div className="col-md-8">
                        <h1 className="">Школа-гимназия №1 имени Героя Советского Союза Михаила Бабкина расположена в центре города Жалал-Абад по улице Ж.Бакиева 17.</h1>
                        <p>Школа одноэтажная,типовая,построена в 1927 году.                         Школа-гимназия с 1965-года носит имя Героя Советсого Союза,майора авиации, летчика-истребителя,выпускника школы Михаила Николаевича Бабкина.
                            Общая площадь школы  -0,92 га,общая куботура здания по внутреннему обмеру-7596,4 м3,общая площадь здания по внутреннему обмеру- 1887,6 м2, школа расчитана на 650 учащихся,а в данное время обучаются 2479 учащихся,отопление автономное,электрическое.

                            Школа расположена в центре города и имеется электричество, чистая вода наружная,рядом со зданием школы  проходит центральная канализация города ,но школа не подключена  к центральной канализации и школьный туалет  находится снаружи.Столовая расчитана на 60 посадочных мест, пользуется септиком,который заполняется каждые два месяца и приходиться вычищать.
                        </p>
                    </div>
                </div>

                <p>
                    Имеется спортивный зал, школьная столовая,которые не соответствуют стандарту.Также имеется спортивная площадка,где занимаються юные футболисты,чемпионы города.В школе имеется единственная в городе школьная электронная библиотека на 20 учеников(расположен в класс кабинете №23),где можно подготовиться к предметным олимпиадам, прочитать электронные книги и пройти онлайн тесты на разные темы.В целях обеспечения безопастности детей,а также  чтобы учащиеся были под наблюдением установлена система видео наблюдения по всему периметру школы, подключены  32 видео камер.Нет актового зала, все школьные мероприятия проводятся  в коридоре школы. Учительская находиться в прихожей школы.Нет читального зала, мастерской и лабораторных кабинетов.
                </p>
                <h5>
                    На 2020-2021-учебный год   укомплектовано 53 класс комплектов,2479 учащихся.Обучение проводиться на русском языке в 3 смены,в 23-класс кабинетах.
                </h5>
                <p><strong>В школе работают 77 учителей.</strong></p>
                <ul>
                    <li>Из них с высшим образованием- 64 учителя</li>
                    <li>со средне специальным образованием -9 человек</li>
                    <li>
                        учителя с бакалавриатом-2, закончившие магистратуру-1,неоконченно высшее-1.
                    </li>
                </ul>
                <p>
                    <strong>
                        В школе трудяться 11 Отличников Образования, два Заслуженных учителя Кыргызской Республики:
                    </strong>
                    <ul>
                        <li>1. Пастельняк Галина Петровна-учитель английского языка,Отличник образования, выпускница нашей школы, Почетный Гражданин города. </li>
                        <li>2.Маматова Гульжан Нейматовна-учитель математики,Отличник Образования.</li>
                    </ul>

                </p>
                <p>
                    Благодаря неустанному труду  педагогов школы выпускники школы обучаются в престижных ВУЗах нашей Республики,России,Германии и Ближнего зарубежья.Учащиеся школы занимают призовые места в городских,областных,Республиканских и Международных предметных олимпиадах.Получают высшие баллы по ОРТ.
                </p>
                <p>
                    С 2015-2020-гг. 8 выпускников школы закончили с Золотой меделью “Алтын Тамга”, 7 учеников с Золотым Сертикатом по ОРТ.
                </p>
                <p>
                    2018-2019-2020-учебном году учителя достигли высших результатов и завоевали в конкурсах “Учитель года” Гран-При и в 2020 году первое место по Республике в конкурсе Учитель года “ Санарип Мугалим”.
                </p>
                <p>
                    Несмотря на трудности школа-гимназия добивается многих успехов и учащиеся участвуют во многих социальных и городских проектах:
                </p>
                <p>
                    “Мы против СПИДа” по программе ЗОЖ,”Умный ребенок” по программе МО,
                    ” Доброе сердце”,”Сен,Ты,YOU”по проекту “Семья основа будущего”,Пушкинское чтение по проекту Россотрудничества, “Сила в знаниях” по проекту городской мэрии,”Мы знатоки ПДД” по проекту безопасная дорога,” Выбор профессии-выбор будущего”по проекту  профориентации “GIZ”.
                </p>
                <p><strong>Проблемы школы:</strong> В связи с тем,что школе уже целый век мы нуждаемся в построении нового учебного корпуса.Здания школы очень старые, штукатурка валится,полы не один раз поменяли. Из-за того,что фундамент садиться полы быстро гниют,запах сырости чувствуется во всех класс кабинетах,в начальном корпусе еле держится потолок, полы уже не знаешь чем еще прикрыть.Трешины на стенах закрываем и приводим в порядок.Благодаря ежегодным косметическим ремонтам школа еще  держится.
                </p>
                <p>Знаем,наша школа - историческая школа,выпустила двух Героев Советского Союза ВОВ: М.Н.Бабкина и Е.К.Мазькова. Двух легендарных героев провожала и встретила,выстояла во многих землетресениях,митингах и селевых потоках.
                </p>
                <p> Со временем число учеников с каждым днем увеличивается, население города растет и мы нуждаемся в ПОМОЩИ!
                </p>
                <p>Несколько раз обращались депутатам  ЖК , Правительству КР,Президентскому аппарату КР. А этот вопрос поднимается еще с 2012 года,но обратили внимание только 2018-году,когда  в кабинетах  в жаркие дни   не чем было дышать,а вонь из-за сырости доходила до двора.
                </p>
                <p>Мы, просим Правительство Кыргызской Республики  проанализировать и построить современную школу для нашего подрастающего поколения на 1000 мест. Трехэтажную,типовую,которая будет соответствовать требованиям безопасной школы и конечно, где мы будем получать образование в две смены.
                </p>
                <p>В 2018-году депутат Жогорку Кенеша Осмонова А.Ш. увидев своими глазами  пригласила членов Правительства КР, были взяты грунтовые анализы,составляли проект,но до сих пор вопрос остается открытым.
                </p>
                <p>Подрастающее поколение просит Правительство нашей Республики уделить нам внимание и оказать помощь в построении нового здания нашей школы,в которой 2022-году будем отмечать 95 летие школы.
                </p>
                <div className="history-titles-block">
                    <p>Директор школы:           </p>
                    <p>                                                        <strong><i> Касымбекова Д.Т.</i></strong></p>
                </div>
            </div>
        </div>
    );
};

export default HistoryIndex;