



export const EduMet =[
    {
        name :"Базисный план 2019-2020",
        link : "https://drive.google.com/file/d/1abf6mspRuPaYV7YyanfYg_NCKzvGYHj8/view?usp=sharing"
    },
    {
        name :"Базисный план 2020-2021",
        link : "https://drive.google.com/file/d/1cfyWY_sTLHjKNPGrgAz1Y1tkuWfwt6_L/view?usp=sharing"
    },
    {
        name :"Базисный план 2021-2022",
        link : "https://drive.google.com/file/d/1BWT6Uz8j-hXkKXc7cGioR2lpCXf0p4-j/view?usp=sharing"
    },
    {
        name :"Kонспект",
        link : "https://drive.google.com/file/d/1g3hsWvD1XMRyClnxlk8JXG865UFTv0cM/view?usp=sharing"
    },
    {
        name :"Качественный и количественный состав учителей СОШГ 1 1.",
        link : "https://drive.google.com/file/d/1n9Dg9_zro19UYFPj5FFJgNFrye5wgTJc/view?usp=sharing"
    },
    {
        name :"График повышение квалификации",
        link : "https://drive.google.com/file/d/1TEAE3FU2mzsd6VtvxRAeLp5NJbJza4-C/view?usp=sharing"
    },
    {
        name :"Ига 20",
        link : "https://drive.google.com/file/d/1uinugpcsik6KoE0KX5GJZ_clsUFPhXCG/view?usp=sharing"
    },
    {
        name :"Отчет соц педагога",
        link : "https://drive.google.com/file/d/198SeQSTZ2rIrNealLOUPYksW1j438Ux-/view?usp=sharing"
    },
    {
        name :"Приказ стим фонд",
        link : "https://drive.google.com/file/d/13QHOvoT0jPxrbPAS5L0442iinNpomF94/view?usp=sharing"
    },
    {
        name :"Приказ этика",
        link : "https://drive.google.com/file/d/18R_xLwe5KTlzhMMT0Kk27QVWHlOITsar/view?usp=sharing"
    },
    {
        name :"Протокол № 1-ОРС",
        link : "https://drive.google.com/file/d/1_QONspx4ksFiwJYkgny6bs2QCSUsk7zx/view?usp=sharing"
    },
    {
        name :"Cтатистический отчет 2019",
        link : "https://drive.google.com/file/d/1VA1fUmpktQ4Xiy2AWa5T7cHXPHNsDIxx/view?usp=sharing"
    },
    {
        name :"Cтатистический отчет 2021",
        link : "https://drive.google.com/file/d/1NYVYoP_VW807sRZzC-xyL3lyMOCb6ttq/view?usp=sharing"
    },
    {
        name :"Cтатистический отчеть 2020",
        link : "https://drive.google.com/file/d/1-PFtLqprawVeV6jsQ1fDoYYPXlqdJWLJ/view?usp=sharing"
    },
    {
        name :"Tарификация (1)",
        link : "https://drive.google.com/file/d/1iQeLTTcQvpXMNL5wYgLqL8wHuU6xbNAA/view?usp=sharing"
    },
    {
        name :"Tарификация (2)",
        link : "https://drive.google.com/file/d/14L9xv6F7uf2lfdTMR26Aht4XLRUK_JDC/view?usp=sharing"
    },
    {
        name :"Tехнологическая карта",
        link : "https://drive.google.com/file/d/1pIx6tfHrKjTCI5sltVHu_wvFT12nqZCe/view?usp=sharing"
    },
    {
        name :"Tку",
        link : "https://drive.google.com/file/d/1AL4oG0mnnxoJlTZn5LkO62sYbMEStzxE/view?usp=sharing"
    },
    {
        name :"Штатное расписание (1)",
        link : "https://drive.google.com/file/d/1j9yyWqAbZGyZCLy6PdDE82YuEccg-n_a/view?usp=sharing"
    },
    {
        name :"Штатное расписание",
        link : "https://drive.google.com/file/d/1HiW9YvyCHbLeEANgjeCUSMaPv15QzOAJ/view?usp=sharing"
    },
    {
        name: "протокол_общеродительского_комитета_№1",
        link: "https://drive.google.com/file/d/1EkUH-zWnW3YFjESKZVjgSQYjmRCRSkir/view?usp=sharing"
    },
    {
        name: "приказ_о_комиссии_по_охране_труда",
        link: "https://drive.google.com/file/d/1tzCDi1DPZDW37q7tKWJdYrR4868GTIvE/view?usp=sharing"
    },
    {
        name: "приказ на ИГА",
        link: "https://drive.google.com/file/d/1owD--hyuT0xbjEc1Z07u81Ef2ucAnp4L/view?usp=sharing"
    },
    {
        name: "план работы школы 2020-2021.",
        link: "https://drive.google.com/file/d/1NznK1wVyZ7xlMhC_lc08DYjC5Z9j4Mz5/view?usp=sharing"
    },
    {
        name: "план работы школы 2019-2020",
        link: "https://drive.google.com/file/d/1x0h8rYE3F-X1YrPYwO_9Fi3Ia1fkCZy7/view?usp=sharing"
    },
    {
        name: "план работы библиотеки на 2021-2022 г",
        link: "https://drive.google.com/file/d/1B2J9rZRjIqzX7cH1BfBz4LIJW8gD5XR-/view?usp=sharing"
    },
    {
        name: "план работа школы 2021-2022",
        link: "https://drive.google.com/file/d/1yjUco2_SER9GBn8ATxe-UJM3mosvgtt9/view?usp=sharing"
    },
    {
        name: "перетарификация",
        link: "https://drive.google.com/file/d/10fKbbnyz_uE9U30tVzB6NpmFtkRWjcnt/view?usp=sharing"
    },
    {
        name: "паспорт учебного кабинета",
        link: "https://drive.google.com/file/d/1rlWDzHDf6G4muNMw0wAjn6_qNTdEBLDg/view?usp=sharing"
    },
    {
        name: "заявка учителя",
        link: "https://drive.google.com/file/d/1V_v-kpKPfvtkIhaOa8x6pp6U3qyoFrC5/view?usp=sharing"
    },
    {
        name: "буйрук №12 (аккредитация)",
        link: "https://drive.google.com/file/d/1hDFC0EwqjuyZQJIU00G-6CqWr8zlPhZZ/view?usp=sharing"
    },
    {
        name: "награды",
        link: "https://drive.google.com/drive/folders/1GYQhcWDwH77Ivo4oIweIr7wqDlMGkEUr?usp=sharing"
    },
    {
        name: "повышение квалификации",
        link: "https://drive.google.com/drive/folders/1mLGOro4_qzto394KXhQqAFQvSHzb3lrt?usp=sharing"
    },
]