import React from 'react';
import MoniLading from "./components/moni/moni-lading";
import {HomeLayout} from "./components/HomeLayout";

const Moni = () => {
    return (
        <HomeLayout>
          <MoniLading/>
        </HomeLayout>
    );
};

export default Moni;