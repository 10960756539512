


export const mtbData = [
    {
      "name":"План работы завхоза на 2021-2022",
      "link" :"https://drive.google.com/file/d/1fi0CEzH8ZGI8uORu9L00TRtEho1Y3kZk/view?usp=sharing"
    },
    {
      "name":"План мероприятий по укреплению материально тех",
      "link" :"https://drive.google.com/file/d/1OCLBkg6ISiRoJoELxlNnEDL84U_pYtXL/view?usp=sharing"
    },
    {
      "name":"План работы по благоустройству 2021-2022 Сошг 1",
      "link" :"https://drive.google.com/file/d/1xflJqm_6lyW6BvjDHMsi556gapQGarIR/view?usp=sharing"
    },
    {
      "name":"Cведения о школе и материально тех",
      "link" :"https://drive.google.com/file/d/1vwJdKpBHBiC7M9tYgzJh1Th43I4wDE28/view?usp=sharing"
    },
    {
      "name" : "Смета расходов 2019-2020 СОШГ 1",
      "link" : "https://drive.google.com/file/d/1_H99Z3W-q2MFhrF0JRftNyzjTuIpFnYp/view?usp=sharing"
    },
    {
      "name" : "Cмета расходов 2020-2021 СОШГ 1.",
      "link" : "https://drive.google.com/file/d/1CKpHGnA5BtiRHss7tLNYByByONizaHpg/view?usp=sharing"
    },
    {
      "name" : "Смета расходов 2021-2022 СОШГ 1",
      "link" : "https://drive.google.com/file/d/1dv-xbNKjm_ydhg_6U7StekexgZgiGS9V/view?usp=sharing"
    },
    {
      "name" : "Положение\n" +
          "\n" +
          "о непрерывном профессиональном развитии\n" +
          "\n" +
          "педагогов\n" +
          "общеобразовательных\n" +
          "\n" +
          "организаций Кыргызской Республики",
      "link" : "https://drive.google.com/file/d/1Ex3N9YyIvJS8sGD7rTjWW9JfmrZSyaBO/view?usp=sharing"
    },
    {
        name:  "Технический паспорт школы",
        link : "https://drive.google.com/file/d/1LBnkr02s7nw3cQ6Ui-50JAdC1ZgE1YTk/view?usp=sharing"
    },
    {
        name:  "смета расходов на 2006 г",
        link : "https://drive.google.com/file/d/1SKshTKo-iByPxSDBDMpDG4og_QcwyvMM/view?usp=sharing"
    },
    {
        name:  "сведения по школе на 2007-2008 г",
        link : "https://drive.google.com/file/d/1kXh3h-gLT16sQW8RP6UQQ-5emPyuZfQx/view?usp=sharing"
    },
    {
        name:  "план земельного участка",
        link : "https://drive.google.com/file/d/1zKs9cCq27bRRL32C9_oZzkfMZNsj2nn_/view?usp=sharing"
    },
    {
        name:  "общие сведения о школе",
        link : "https://drive.google.com/file/d/13JFK48mWYylUh2JgEJAGSOhI37wZ0sF2/view?usp=sharing"
    },
    {
        name:  "инвентаризационное дело",
        link : "https://drive.google.com/file/d/1sarNGffPuhBHdxd3kzwue7WshqKMXIlb/view?usp=sharing"
    },
    {
        name:  "акт сверка",
        link : "https://drive.google.com/file/d/1Z-NNeEajw-tMi8PPDjJsj1xRP2Xjjajd/view?usp=sharing"
    },
    {
        name:  "акт приема передачи библиотеки",
        link : "https://drive.google.com/file/d/10to0i4ksf1SyaqhaM-IO07fKAndUL1Rh/view?usp=sharing"
    },
    {
        name:  "16e43a26-e40d-4702-8530-139f85316127",
        link : "https://drive.google.com/file/d/1xcsZO8m_Qijqb_SNysl6FwWmWfYioslC/view?usp=sharing"
    },
]