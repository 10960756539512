import React from 'react';
import {HomeLayout} from "./components/HomeLayout";
import {mtbData} from "./components/mtbData";
import {NavLink} from "react-bootstrap";
import file from "./images/folder.png"

const Mtb = () => {
    return (
        <HomeLayout>
            <div className="container">
                <div className="news-title">
                    <h1>
                        МТБ документы
                    </h1>
                </div>
                <div className="row">
                    {
                        mtbData.map(item=>{
                            return (
                                <NavLink href={item.link} className="col-lg-3 gap-4 col-md-4 col-sm-6">
                                    <a>
                                        <div className="mtb_block">
                                            <img src={file} alt=""/>
                                            <h1>{item.name}</h1>
                                        </div>
                                    </a>
                                </NavLink>
                            )
                        })
                    }
                </div>
            </div>
        </HomeLayout>
    );
};

export default Mtb;