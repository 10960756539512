import React from 'react';
import moni from "../../images/Снимок экрана 2022-04-10 в 23.07.26.png"
import {NavLink} from "react-bootstrap";
import {Data} from "./data";
import file from "../../images/folder.png";
const MoniLading = () => {
    return (
        <div className={"container"}>
            <div className="news-title">
                <h1>Mониторинг</h1>
            </div>
            <img src={moni} alt="" className="moni-img"/>
            <div className="row mb-5">
                {
                    Data.map(item=>{
                        return (
                            <NavLink href={item.link} className="col-lg-3 gap-4 col-md-4 col-sm-6">
                                <a>
                                    <div className="mtb_block">
                                        <img src={file} alt=""/>
                                        <h1>{item.name}</h1>
                                    </div>
                                </a>
                            </NavLink>
                        )
                    })
                }
            </div>

        </div>
    );
};

export default MoniLading;