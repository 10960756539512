import img1 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.05.25.png"
import img2 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.06.01.png"
import img3 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.06.59.png"
import img4 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.07.24.png"
import img5 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.08.19.png"
import img6 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.07.41.png"
import img7 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.08.49.png"
import img8 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.09.36.png"
import img9 from "../../images/newsIMG/Снимок экрана 2022-04-10 в 00.10.05.png"


export const Data = [
    {
        "img": img1,
        "title": "Жаштарды патриоттуулукка, мекенди суйууго тарбиялоо максатында жана атуулдук ырларды мыкты аткаруу боюнча \"Жоокер ыры\" 7-Республикалык корком сынактын Жалал-Абад шаардык турунда \"Согуш ырлары\" аттуу обондуу ырлар сынагында 1-орунду жана областтык турунда да 1-орунду ээлеп,Республикалык туруна жолдомо алгандыгы учун Жалал-Абад шаарындагы #1 М.Бабкин атындагы мектеп-гимназиясынын 10-классынын Аскарбекова Диананы куттуктайбыз! Диананын Республикалык турунда да байгелуу ,алдынкы орунду алып келишин каалайбыз!",
    },
    {
        "img": img2,
        "title": "Диана Аскарбекова, ученица 10 класса, школа гимназии №1 им. М. Бабкина, стала обладательницей Гран-при Республиканского конкурса.",
    },
    {
        "img": img3,
        "title": "04.03.2022, с 10-а классом провели открытый урок на тему:\"Суд над Раскольниковым\" по роману Ф.М.Достоевского \"Преступление и Наказание\"",
    },
    {
        "img": img4,
        "title": "25-26-февраля прошла Облсатная Олимпиада в г.Жалал-Абад\n" +
            "Наши ученики тоже участвовали и заняли призовые места:\n" +
            "Исманалиев Сулайман 2-место по математике.\n" +
            "Шайдуллаева Айназик\n" +
            "3-место по русскому языку.",
    },
    {
        "img": img5,
        "title": "В ходе проведения мероприятия были возложено цветы к памятнику участников ВОВ закончивших эту школу.",
    },
    {
        "img": img6,
        "title": "Наши одаренные, умные ученики стали обладателями городских олимпиад и удостоились дипломов со стороны Жалал-Абадского городского отдела образования.",
    },
    {
        "img": img7,
        "title": "22 февраля 2022 года в школе-гимназии #1 было проведено торжественное мероприятие в честь столетия участника Великой Отечественной Войны героя Советского Союза летчика Михаила Бабкина.",
    },
    {
        "img": img8,
        "title": "Биз алп акыбыз А.Осмоновдун ырларын, улуу жазуучубуз Ч.Айтматовдун чыгармаларын сүйүп окуп, жан дүйнөбүзго чоң таасир алып, урматтап жүрөлү...Ала-Тоодон ааламга үн салган алп акыбыз жана улуу жазуучубуз ар дайым биз менен жана биздин жүрөгүбүздүн түпкүрүнөн орун алат.Алардын экинчи өмүрү болгон ырлары, чыгармалары да дүйнөнү дүңгүрөтүп, аалам аралап, жашай берет.",
    },
    {
        "img": img9,
        "title": "Жалал-Абад шаарындагы жалпы билим беруучу мектептердин мугалимдеринин арасында өткөрулгөн \"2021-жылдын Мыкты кыргыз тилчи\" сынагында #1 Михаил Бабкин атындагы мектеп гимназиясынын кыргыз тили жана адабият мугалими Суханбердиева Бегайым Токбаевна 2-орундуу ээледи.",
    },
]
