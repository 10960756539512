import t1 from "./images/teachers/IMAGE 2022-04-12 10:59:29.jpg"
import t2 from "./images/teachers/IMAGE 2022-04-12 10:59:26.jpg"
import t3 from "./images/teachers/IMAGE 2022-04-12 10:59:30.jpg"
import t4 from "./images/teachers/IMAGE 2022-04-12 10:59:31.jpg"
import t5 from "./images/teachers/IMAGE 2022-04-12 10:59:34.jpg"
import t6 from "./images/teachers/IMAGE 2022-04-12 10:59:50.jpg"
import t7 from "./images/teachers/IMAGE 2022-04-12 11:00:12.jpg"
import t8 from "./images/teachers/IMAGE 2022-04-12 11:00:13.jpg"
import t9 from "./images/teachers/IMAGE 2022-04-12 11:00:14.jpg"
import t10 from "./images/teachers/IMAGE 2022-04-12 11:00:16.jpg"
import t11 from "./images/teachers/IMAGE 2022-04-12 11:00:17.jpg"
import t12 from "./images/teachers/IMAGE 2022-04-12 11:00:18.jpg"
import t13 from "./images/teachers/IMAGE 2022-04-12 11:00:20.jpg"
import t14 from "./images/teachers/IMAGE 2022-04-12 11:00:21.jpg"
import t15 from "./images/teachers/IMAGE 2022-04-12 11:00:22.jpg"
import t16 from "./images/teachers/IMAGE 2022-04-12 11:00:24.jpg"
import t17 from "./images/teachers/IMAGE 2022-04-12 11:00:25.jpg"
import t18 from "./images/teachers/IMAGE 2022-04-12 10:59:26.jpg"
import t19 from "./images/teachers/IMAGE 2022-04-12 11:00:27.jpg"
import t20 from "./images/teachers/IMAGE 2022-04-12 11:00:29.jpg"
import t21 from "./images/teachers/IMAGE 2022-04-12 10:59:30.jpg"
import t22 from "./images/teachers/IMAGE 2022-04-12 10:59:31.jpg"
import t23 from "./images/teachers/IMAGE 2022-04-12 11:00:33.jpg"
import t24 from "./images/teachers/IMAGE 2022-04-12 11:00:34.jpg"
import t25 from "./images/teachers/IMAGE 2022-04-12 11:00:35.jpg"
import t26 from "./images/teachers/IMAGE 2022-04-12 11:00:36.jpg"
import t27 from "./images/teachers/IMAGE 2022-04-12 11:00:37.jpg"
import t28 from "./images/teachers/IMAGE 2022-04-12 11:00:38.jpg"
import t29 from "./images/teachers/IMAGE 2022-04-12 11:00:39.jpg"
import t30 from "./images/teachers/IMAGE 2022-04-12 11:00:41.jpg"
import t31 from "./images/teachers/IMAGE 2022-04-12 11:00:42.jpg"
import t32 from "./images/teachers/IMAGE 2022-04-12 11:00:43.jpg"
import t33 from "./images/teachers/IMAGE 2022-04-12 11:00:44.jpg"
import t34 from "./images/teachers/IMAGE 2022-04-12 11:00:45.jpg"
import t35 from "./images/teachers/IMAGE 2022-04-12 11:00:46.jpg"
import t36 from "./images/teachers/IMAGE 2022-04-12 11:00:48.jpg"
import t37 from "./images/teachers/IMAGE 2022-04-12 11:00:52.jpg"
import t38 from "./images/teachers/IMAGE 2022-04-12 11:00:53.jpg"
import t39 from "./images/teachers/IMAGE 2022-04-12 11:00:57.jpg"
import t40 from "./images/teachers/IMAGE 2022-04-12 11:00:59.jpg"
import t41 from "./images/teachers/IMAGE 2022-04-12 11:01:00.jpg"
import t42 from "./images/teachers/IMAGE 2022-04-12 11:02:01.jpg"
import t43 from "./images/teachers/IMAGE 2022-04-12 11:00:52.jpg"
import t45 from "./images/teachers/IMAGE 2022-04-12 11:01:04.jpg"
import t46 from "./images/teachers/IMAGE 2022-04-12 11:01:06.jpg"
import t47 from "./images/teachers/IMAGE 2022-04-12 11:01:08.jpg"
import t48 from "./images/teachers/IMAGE 2022-04-12 11:01:04.jpg"
import t49 from "./images/teachers/IMAGE 2022-04-12 11:01:09.jpg"
import t50 from "./images/teachers/IMAGE 2022-04-12 11:01:33.jpg"
import t51 from "./images/teachers/IMAGE 2022-04-12 11:01:34.jpg"
import t52 from "./images/teachers/IMAGE 2022-04-12 11:01:36.jpg"
import t53 from "./images/teachers/IMAGE 2022-04-12 11:01:37.jpg"
import t54 from "./images/teachers/IMAGE 2022-04-12 11:01:38.jpg"
import t55 from "./images/teachers/IMAGE 2022-04-12 11:01:39.jpg"
import t56 from "./images/teachers/IMAGE 2022-04-12 11:01:40.jpg"
import t57 from "./images/teachers/IMAGE 2022-04-12 11:01:41.jpg"
import t58 from "./images/teachers/IMAGE 2022-04-12 11:01:43.jpg"
import t59 from "./images/teachers/IMAGE 2022-04-12 11:01:45.jpg"
import t60 from "./images/teachers/IMAGE 2022-04-12 11:01:47.jpg"
import t61 from "./images/teachers/IMAGE 2022-04-12 11:01:48.jpg"
import t62 from "./images/teachers/IMAGE 2022-04-12 11:01:50.jpg"
import t63 from "./images/teachers/IMAGE 2022-04-12 11:01:51.jpg"
import t64 from "./images/teachers/IMAGE 2022-04-12 11:01:53.jpg"
import t65 from "./images/teachers/IMAGE 2022-04-12 11:01:54.jpg"
import t66 from "./images/teachers/IMAGE 2022-04-12 11:01:57.jpg"
import t67 from "./images/teachers/IMAGE 2022-04-12 11:02:01.jpg"
import t68 from "./images/teachers/IMAGE 2022-04-12 11:02:02.jpg"
import t69 from "./images/teachers/IMAGE 2022-04-12 11:02:03.jpg"
import t71 from "./images/teachers/IMAGE 2022-04-12 11:02:05.jpg"
import t72 from "./images/teachers/IMAGE 2022-04-12 11:02:06.jpg"
import t73 from "./images/teachers/IMAGE 2022-04-12 11:02:07.jpg"
// importt34 from "./images/teachers"
// import t35 from "./images/teachers"
// import t36 from "./images/teachers"
// import t37 from "./images/teachers"
// import t from "./images/teachers"


export  const teachersData = [
    {"img": t4},
    {"img": t1},
    // {"img": t2},
    // {"img": t3},
    {"img": t5},
    // {"img": t6},
    {"img": t7},
    {"img": t8},
    {"img": t9},
    {"img": t10},
    {"img": t11},
    {"img": t12},
    {"img": t13},
    {"img": t14},
    {"img": t15},
    {"img": t16},
    {"img": t17},
    {"img": t18},
    {"img": t19},
    {"img": t20},
    {"img": t21},
    // {"img": t22},
    {"img": t23},
    {"img": t24},
    // {"img": t25},
    {"img": t26},
    {"img": t27},
    {"img": t28},
    {"img": t29},
    {"img": t30},
    {"img": t31},
    {"img": t32},
    {"img": t33},
    {"img": t34},
    {"img": t35},
    {"img": t36},
    {"img": t37},
    {"img": t38},
    {"img": t39},
    {"img": t40},
    {"img": t41},
    {"img": t42},
    {"img": t43},
    {"img": t45},
    {"img": t46},
    {"img": t47},
    {"img": t48},
    {"img": t49},
    {"img": t50},
    {"img": t51},
    {"img": t52},
    {"img": t53},
    {"img": t54},
    {"img": t55},
    {"img": t56},
    {"img": t57},
    {"img": t58},
    {"img": t59},
    {"img": t60},
    {"img": t61},
    {"img": t62},
    {"img": t63},
    {"img": t64},
    {"img": t65},
    {"img": t66},
    {"img": t67},
    {"img": t68},
    {"img": t69},
    {"img": t71},
    {"img": t72},
    {"img": t73},
]