

export const Data = [
    {
        "name": "ГОСУДАРСТВЕННЫЙ ОБРАЗОВАТЕЛЬНЫЙ СТАНДАРТ",
        "link" : "https://drive.google.com/file/d/1oqtQLgsE1hdcAx11Sv1bxkRfPB9SWfz2/view?usp=sharing"
    },
    {
        "name": "О порядке рассмотрения обращений граждан",
        "link" : "https://drive.google.com/file/d/1OT67G0aEMqEwrUOG4hbVSZ4V3Hk9zibq/view?usp=sharing"
    },
    {
        "name": "Закон КР «Об организации питания учащихся в общеобразовательных организациях",
        "link" : "https://drive.google.com/file/d/1Xmy6HnYKolh4RlMIJGIkPGOXz5f3P5E_/view?usp=sharing"
    },
    {
        "name": "Об основах профилактики правонарушений",
        "link" : "https://drive.google.com/file/d/1BvtLZTCoQ674PBZit1RA7OcZGxppDddF/view?usp=sharing"
    },
    {
        "name": "КОДЕКС КЫРГЫЗСКОЙ РЕСПУБЛИКИ О ДЕТЯХ",
        "link" : "https://drive.google.com/file/d/1sqYKHCvaxuDO6Ec0up7l5puOm7xVuSMx/view?usp=sharing"
    },
    {
        "name": "О Конституции Кыргызской Республики",
        "link" : "https://drive.google.com/file/d/13HYocFP_a7a3J683YBae_J_swc0QjIGC/view?usp=sharing"
    },
    {
        "name": "О государственном языке Кыргызской Республики",
        "link" : "https://drive.google.com/file/d/1OJnPhRRPut8NyHzZNFeYqWn_MSRauko6/view?usp=sharing"
    },
    {
        "name": "О противодействии коррупции",
        "link" : "https://drive.google.com/file/d/1hNcAOweNJw-qQduLjbRiYr3xI4rp8KsG/view?usp=sharing"
    },
    {
        "name": "О статусе учителя",
        "link" : "https://drive.google.com/file/d/1RsWnWaxOfGdlf5yQw5-WeLG0dwUhLIya/view?usp=sharing"
    },
    {
        "name": "Об образовании",
        "link" : "https://drive.google.com/file/d/13d1XlaVLRbeN6_8X0F9dgKo6RYwB7Fer/view?usp=sharing"
    },
    {
        "name": "Программа развития образования в КР",
        "link" : "https://drive.google.com/file/d/1aGjh6i6h6GYzE08M2le2MzTRAKNsn0Rw/view?usp=sharing"
    }
]