import React from 'react';
import {HomeLayout} from "./components/HomeLayout";
import NewsLanding from "./components/news/news-landing";

const NewsIndex = () => {
    return (
        <HomeLayout>
            <NewsLanding/>
        </HomeLayout>
    );
};

export default NewsIndex;